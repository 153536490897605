import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Table, Card, Row, Col, Container, Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { BiArrowToLeft, BiArrowToRight, BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import RefreshButton from './Refresh';

firebase.app();

const getColorForQuery = (query, queries) => {
    const index = queries.indexOf(query);
    const colors = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93', '#fcf6bd', '#d0f4de', '#a9def9', '#e4c1f9'];
    return colors[index % colors.length];
};

const parseQueries = (input) => {
    const regex = /(?:[^\s"]+|"[^"]*")+/g;
    const matches = input.match(regex) || [];
    return matches.map(query => query.replace(/"/g, '').trim().toLowerCase());
};

export default function Clients() {
    const [clients, setClients] = useState([]);
    const [viewMode, setViewMode] = useState('table');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [filters, setFilters] = useState({
        networkName: [], manufacturer: [], lastConnectedNetworkConnectionType: [], vlan: [], lastConnectedNetworkDeviceName: [], os: [], switchport: [], status: [], ssid: []
    });
    const [selectedFilters, setSelectedFilters] = useState({
        networkName: [], manufacturer: [], lastConnectedNetworkConnectionType: [], vlan: [], lastConnectedNetworkDeviceName: [], os: [], switchport: [], status: [], ssid: []
    });
    const [displayNames, setDisplayNames] = useState({
        networkName: 'Network Name', manufacturer: 'Device Manufacturer', lastConnectedNetworkConnectionType: 'Connection Type', vlan: 'VLAN ID / Tag', lastConnectedNetworkDeviceName: 'Device Name', os: 'Device OS', switchport: 'Switch Port', status: 'Device Status', ssid: 'SSID / Wi-Fi'
    });
    const [filterSortConfig, setFilterSortConfig] = useState({
        networkName: { key: 'name', direction: 'asc' },
        manufacturer: { key: 'name', direction: 'asc' },
        lastConnectedNetworkConnectionType: { key: 'name', direction: 'asc' },
        vlan: { key: 'name', direction: 'asc' },
        lastConnectedNetworkDeviceName: { key: 'name', direction: 'asc' },
        os: { key: 'name', direction: 'asc' },
        switchport: { key: 'name', direction: 'asc' },
        status: { key: 'name', direction: 'asc' },
        ssid: { key: 'name', direction: 'asc' }
    });
    const [expandedFilters, setExpandedFilters] = useState({
        networkName: false, manufacturer: false, lastConnectedNetworkConnectionType: false, vlan: false, lastConnectedNetworkDeviceName: false, os: false, switchport: false, status: false, ssid: false
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(75);
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const db = firebase.database().ref('/clients');
            db.on('value', snapshot => {
                const data = snapshot.val();
                let clientsArray = [];
                let filterOptions = {
                    networkName: new Set(), manufacturer: new Set(), lastConnectedNetworkConnectionType: new Set(), vlan: new Set(), lastConnectedNetworkDeviceName: new Set(), os: new Set(), switchport: new Set(), status: new Set(), ssid: new Set()
                };
                for (let clientId in data) {
                    for (let networkName in data[clientId]) {
                        const client = { clientId, networkName, ...data[clientId][networkName] };
                        clientsArray.push(client);
                        filterOptions.networkName.add(client.networkName || '(empty)');
                        filterOptions.manufacturer.add(client.manufacturer || '(empty)');
                        filterOptions.lastConnectedNetworkConnectionType.add(client.lastConnectedNetworkConnectionType || '(empty)');
                        filterOptions.vlan.add(client.vlan || '(empty)');
                        filterOptions.lastConnectedNetworkDeviceName.add(client.lastConnectedNetworkDeviceName || '(empty)');
                        filterOptions.os.add(client.os || '(empty)');
                        filterOptions.switchport.add(client.switchport || '(empty)');
                        filterOptions.status.add(client.status || '(empty)');
                        filterOptions.ssid.add(client.ssid || '(empty)');
                    }
                }
                setClients(clientsArray);
                setFilters({
                    networkName: Array.from(filterOptions.networkName).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    manufacturer: Array.from(filterOptions.manufacturer).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    lastConnectedNetworkConnectionType: Array.from(filterOptions.lastConnectedNetworkConnectionType).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    vlan: Array.from(filterOptions.vlan).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    lastConnectedNetworkDeviceName: Array.from(filterOptions.lastConnectedNetworkDeviceName).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    os: Array.from(filterOptions.os).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    switchport: Array.from(filterOptions.switchport).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    status: Array.from(filterOptions.status).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b)),
                    ssid: Array.from(filterOptions.ssid).sort((a, b) => a === '(empty)' ? -1 : b === '(empty)' ? 1 : a.localeCompare(b))
                });
            });
        };
        fetchData();
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const searchQuery = query.get('search');
        if (searchQuery) {
            setSearchTerm(searchQuery);
        }
    }, [location.search]);

    const handleFilterChange = (filterType, value) => {
        setSelectedFilters(prev => {
            const currentValues = new Set(prev[filterType]);
            if (currentValues.has(value)) {
                currentValues.delete(value);
            } else {
                currentValues.add(value);
            }
            return { ...prev, [filterType]: Array.from(currentValues) };
        });
    };

    const filteredClients = clients.filter(client => {
        const queries = parseQueries(searchTerm);
        const matchesSearch = queries.length === 0 || queries.some(query => (
            client.clientDescription.toLowerCase().includes(query) ||
            client.networkName.toLowerCase().includes(query) ||
            client.manufacturer.toLowerCase().includes(query) ||
            client.clientIpv4Address.toLowerCase().includes(query) ||
            client.ssid.toLowerCase().includes(query) ||
            client.clientMac.toLowerCase().includes(query) ||
            client.status.toLowerCase().includes(query) ||
            client.os.toLowerCase().includes(query) ||
            client.switchport.toLowerCase().includes(query) ||
            client.lastConnectedNetworkConnectionType.toLowerCase().includes(query) ||
            client.lastConnectedNetworkDeviceName.toLowerCase().includes(query)
        ));
        return (
            matchesSearch &&
            (selectedFilters.networkName.length === 0 || selectedFilters.networkName.includes(client.networkName || '(empty)')) &&
            (selectedFilters.manufacturer.length === 0 || selectedFilters.manufacturer.includes(client.manufacturer || '(empty)')) &&
            (selectedFilters.lastConnectedNetworkConnectionType.length === 0 || selectedFilters.lastConnectedNetworkConnectionType.includes(client.lastConnectedNetworkConnectionType || '(empty)')) &&
            (selectedFilters.vlan.length === 0 || selectedFilters.vlan.includes(client.vlan || '(empty)')) &&
            (selectedFilters.lastConnectedNetworkDeviceName.length === 0 || selectedFilters.lastConnectedNetworkDeviceName.includes(client.lastConnectedNetworkDeviceName || '(empty)')) &&
            (selectedFilters.os.length === 0 || selectedFilters.os.includes(client.os || '(empty)')) &&
            (selectedFilters.switchport.length === 0 || selectedFilters.switchport.includes(client.switchport || '(empty)')) &&
            (selectedFilters.status.length === 0 || selectedFilters.status.includes(client.status || '(empty)')) &&
            (selectedFilters.ssid.length === 0 || selectedFilters.ssid.includes(client.ssid || '(empty)'))
        );
    });

    const sortedClients = [...filteredClients].sort((a, b) => {
        if (sortConfig.key) {
            if (sortConfig.direction === 'asc') {
                return (a[sortConfig.key] || '').localeCompare(b[sortConfig.key] || '');
            } else {
                return (b[sortConfig.key] || '').localeCompare(a[sortConfig.key] || '');
            }
        }
        return 0;
    });

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleFilterSortChange = (filterType, key) => {
        setFilterSortConfig(prevConfig => ({
            ...prevConfig,
            [filterType]: { key, direction: prevConfig[filterType].direction === 'asc' ? 'desc' : 'asc' }
        }));
    };

    const sortedFilters = (filterType) => {
        const filterArray = filters[filterType].map(value => ({
            name: value,
            count: filteredClients.filter(client => (client[filterType] || '(empty)') === value).length
        }));
        return filterArray.sort((a, b) => {
            if (filterSortConfig[filterType].key === 'name') {
                return filterSortConfig[filterType].direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else {
                return filterSortConfig[filterType].direction === 'asc' ? a.count - b.count : b.count - a.count;
            }
        }).slice(0, expandedFilters[filterType] ? undefined : 10);
    };

    const toggleFilterExpansion = (filterType) => {
        setExpandedFilters(prev => ({
            ...prev,
            [filterType]: !prev[filterType]
        }));
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(sortedClients.length / itemsPerPage);
    const displayedClients = sortedClients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const maxPageNumbers = 10;
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = () => {
        const pageLinks = [];
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

        if (endPage - startPage + 1 < maxPageNumbers) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageLinks.push(
                <Button
                    variant="link"
                    key={i}
                    onClick={() => handlePageChange(i)}
                    active={i === currentPage}
                    style={{ minWidth: '50px', textAlign: 'center' }}
                    size="sm"
                >
                    {i}
                </Button>
            );
        }

        return pageLinks;
    };

    const getConnectionTypeIcon = (type) => {
        if (type === "Wireless") return <i className="bi bi-wifi"></i>;
        if (type === "Wired") return <i className="bi bi-ethernet"></i>;
        return null;
    };

    const getStatusClassName = (status) => {
        if (status === "Offline") return "text-danger";
        if (status === "Online") return "text-success";
        return "";
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const convertKBtoGB = (kb) => {
        return (kb / (1024 * 1024)).toFixed(3);
    };

    return (
        <Container fluid className="px-0 mx-0">
            <Row>
                <Col xs={{ span: 12, order: 12 }} md={{ span: 2, order: 1 }} id="sideNav" className="px-0" style={{ fontSize: "0.8rem" }}>
                    <h4 className="ml-2">Attribute Filters</h4>
                    {Object.keys(filters).map(filterType => (
                        <div key={filterType}>
                            <h5 className="text-warning mt-2 pt-2 Xborder-top"><span className="ml-2">{displayNames[filterType]}</span></h5>
                            <Button variant="link" size="sm" onClick={() => handleFilterSortChange(filterType, 'name')}>
                                Sort by Name {filterSortConfig[filterType].key === 'name' ? (filterSortConfig[filterType].direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                            </Button>
                            <Button variant="link" size="sm" onClick={() => handleFilterSortChange(filterType, 'count')}>
                                Sort by Count {filterSortConfig[filterType].key === 'count' ? (filterSortConfig[filterType].direction === 'asc' ? <i className="bi bi-sort-numeric-up"></i> : <i className="bi bi-sort-numeric-down"></i>) : ''}
                            </Button>
                            <Form checklist>
                                <ul className="px-0 mb-0 ml-1">
                                    {sortedFilters(filterType).map(({ name, count }) => (
                                        <li key={name} className="text-light">
                                            <Form.Check
                                                type="checkbox"
                                                label={`${name} (${count})`}
                                                checked={selectedFilters[filterType].includes(name)}
                                                onChange={() => handleFilterChange(filterType, name)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                {!expandedFilters[filterType] && filters[filterType].length > 10 && (
                                    <Button variant="link" size="sm" onClick={() => toggleFilterExpansion(filterType)}>
                                        ...view more
                                    </Button>
                                )}
                            </Form>
                        </div>
                    ))}
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 10, order: 2 }} id="mainView">
                    <Row className="pb-0 mb-0">
                        <Col md={8} className="text-justify">
                            <span className="h4 text-warning"><i className="bi bi-boxes"></i>&nbsp;Modular Search</span>&nbsp;
                            <span className="text-light">This search supports 9(nine) simultaneous lookups, separated by spaces. <span className="text-light">Use "quotations" when searching for phrases or values with blank spaces.</span> <span className="text-info">Change to "EXTD" for more information on each client.</span>&nbsp;<span className="text-warning">IPv4 with "@ #ms" indicate UpTime Monitors.</span>&nbsp;

                                <span className="text-light" alt="Multi-value lookups will update the color of related IPv4" title="Multi-value lookups will update the color of related IPv4">Multi-value lookups will update the color of related Name/MFG/IPv4.</span>&nbsp;

                                <span>
                                    <i class="bi bi-1-circle-fill h5" style={{ color: '#ff595e' }}></i>&nbsp;
                                    <i class="bi bi-2-circle-fill h5" style={{ color: '#ffca3a' }}></i>&nbsp;
                                    <i class="bi bi-3-circle-fill h5" style={{ color: '#8ac926' }}></i>&nbsp;
                                    <i class="bi bi-4-circle-fill h5" style={{ color: '#1982c4' }}></i>&nbsp;
                                    <i class="bi bi-5-circle-fill h5" style={{ color: '#6a4c93' }}></i>&nbsp;
                                    <i class="bi bi-6-circle-fill h5" style={{ color: '#fcf6bd' }}></i>&nbsp;
                                    <i class="bi bi-7-circle-fill h5" style={{ color: '#d0f4de' }}></i>&nbsp;
                                    <i class="bi bi-8-circle-fill h5" style={{ color: '#a9def9' }}></i>&nbsp;
                                    <i class="bi bi-9-circle-fill h5" style={{ color: '#e4c1f9' }}></i>&nbsp;
                                </span>

                            </span>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Row className="text-center">
                                <span>SORTING ORDER: Click to change</span>
                            </Row>
                            <Row className="text-center">
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('lastSeen')}>
                                        Active {sortConfig.key === 'lastSeen' ? (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>) : ''}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('clientDescription')}>
                                        Name {sortConfig.key === 'clientDescription' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('clientIpv4Address')}>
                                        IPv4 {sortConfig.key === 'clientIpv4Address' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-numeric-up"></i> : <i className="bi bi-sort-numeric-down-alt"></i>) : ''}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('manufacturer')}>
                                        MFG {sortConfig.key === 'manufacturer' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('networkName')}>
                                        NW {sortConfig.key === 'networkName' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={2} className="mx-0 px-0">
                                    <Button variant="link" size="sm" onClick={() => requestSort('lastConnectedNetworkDeviceName')}>
                                        Uplink {sortConfig.key === 'lastConnectedNetworkDeviceName' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="pb-1 mb-1">
                        <Col xs={12} md={3} className="pt-2">
                            <Form.Control
                                type="text"
                                placeholder='Clickshare "Texas Instruments" 10.67'
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </Col>
                        <Col xs={4} sm={1} className="text-center pt-2">
                            <Form.Control
                                as="select"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                style={{ textAlign: "center" }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                                <option value={sortedClients.length}>All</option>
                            </Form.Control>
                        </Col>
                        <Col md={1} className="d-none">
                            <Form.Check
                                type="radio"
                                label="Simple"
                                name="viewMode"
                                checked={viewMode === 'table'}
                                onChange={() => setViewMode('table')}
                            />
                        </Col>
                        <Col md={1} className="d-none">
                            <Form.Check
                                type="radio"
                                label="Detailed"
                                name="viewMode"
                                checked={viewMode === 'cards'}
                                onChange={() => setViewMode('cards')}
                            />
                        </Col>
                        <Col xs={4} sm={1} className="pt-2">
                            <Form.Group controlId="setViewMode">
                                <ToggleButtonGroup type="radio" name="clients-view" size="sm" defaultValue="table">
                                    <ToggleButton
                                        name="viewMode"
                                        variant="outline-success"
                                        className="shadow-sm"
                                        checked={viewMode === 'table'}
                                        onChange={() => setViewMode('table')}
                                        value="table">
                                        BASE
                                    </ToggleButton>
                                    <ToggleButton
                                        name="viewMode"
                                        variant="outline-info"
                                        className="shadow-sm"
                                        checked={viewMode === 'cards'}
                                        onChange={() => setViewMode('cards')}
                                        value="cards">
                                        EXTD
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={4} sm={1} className="pt-2">
                            <RefreshButton />
                        </Col>
                        <Col md={6} className="text-center mx-0 px-0">
                            <Button variant="link" onClick={() => handlePageChange(1)} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiArrowToLeft />
                            </Button>
                            <Button variant="link" onClick={() => handlePageChange(Math.max(1, currentPage - 1))} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiLeftArrow />
                            </Button>
                            {renderPageNumbers()}
                            <Button variant="link" onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiRightArrow />
                            </Button>
                            <Button variant="link" onClick={() => handlePageChange(totalPages)} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiArrowToRight />
                            </Button>
                        </Col>
                    </Row>

                    {viewMode === 'table' ? (
                        <Table striped bordered hover style={{ fontSize: "0.85rem" }}>
                            <thead>
                                <tr>
                                    <th>
                                        <Button variant="link" size="sm" onClick={() => requestSort('clientDescription')} style={{ fontWeight: 'bold' }}>
                                            Client Name / Note {sortConfig.key === 'clientDescription' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th className="table-desktop-only">
                                        <Button variant="link" size="sm" onClick={() => requestSort('manufacturer')} style={{ fontWeight: 'bold' }}>
                                            Manufacturer {sortConfig.key === 'manufacturer' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th>
                                        <Button variant="link" size="sm" onClick={() => requestSort('clientIpv4Address')} style={{ fontWeight: 'bold' }}>
                                            IPv4 {sortConfig.key === 'clientIpv4Address' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-numeric-up"></i> : <i className="bi bi-sort-numeric-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th className="table-desktop-only">
                                        <Button variant="link" size="sm" onClick={() => requestSort('networkName')} style={{ fontWeight: 'bold' }}>
                                            Network Name {sortConfig.key === 'networkName' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th className="d-none">
                                        Connection Type
                                    </th>
                                    <th className="table-desktop-only">
                                        <Button variant="link" size="sm" onClick={() => requestSort('lastConnectedNetworkDeviceName')} style={{ fontWeight: 'bold' }}>
                                            Last Uplink {sortConfig.key === 'lastConnectedNetworkDeviceName' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th className="table-desktop-only">
                                        <Button variant="link" size="sm" onClick={() => requestSort('lastSeen')} style={{ fontWeight: 'bold' }}>
                                            Last Seen {sortConfig.key === 'lastSeen' ? (sortConfig.direction === 'asc' ? <i className="bi bi-sort-alpha-up"></i> : <i className="bi bi-sort-alpha-down-alt"></i>) : ''}
                                        </Button>
                                    </th>
                                    <th className="d-none">
                                        Wireless Network
                                    </th>
                                    <th className="d-none">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedClients.map(client => {
                                    const queries = parseQueries(searchTerm);
                                    const matchingQuery = queries.find(query => client.clientIpv4Address.toLowerCase().includes(query) || client.manufacturer.toLowerCase().includes(query) || client.clientDescription.toLowerCase().includes(query));
                                    const queryColor = matchingQuery ? getColorForQuery(matchingQuery, queries) : null;
                                    return (
                                        <tr key={`${client.clientId}-${client.networkName}`}>
                                            <td><span className={getStatusClassName(client.status)}>
                                                {getConnectionTypeIcon(client.lastConnectedNetworkConnectionType)}</span>&nbsp;&nbsp;{client.clientDescription}

                                            </td>
                                            <td className="table-desktop-only" style={{ color: queryColor }}>{client.manufacturer}</td>
                                            <td>
                                                <span className="text-left" style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier", color: queryColor }}>
                                                    {client.clientIpv4Address}
                                                </span>
                                                <span style={{ float: 'right' }} className="text-warning">
                                                    <small><FetchMonitorResponseTime ipv4={client.clientIpv4Address} /></small>
                                                </span>
                                            </td>
                                            <td className="table-desktop-only">{client.networkName}</td>
                                            <td className="d-none">
                                                <span className={getStatusClassName(client.status)}>
                                                    {getConnectionTypeIcon(client.lastConnectedNetworkConnectionType)}</span>{client.lastConnectedNetworkConnectionType}

                                            </td>
                                            <td className="table-desktop-only">{client.lastConnectedNetworkDeviceName} <span className="text-warning">{client.switchport && ` @ Port ${client.switchport}`}</span></td>
                                            <td className="table-desktop-only">{formatDateTime(client.lastSeen)}</td>
                                            <td className="d-none">{client.ssid}</td>
                                            <td className="d-none">{client.status}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <Row>
                            {displayedClients.map(client => {
                                const queries = parseQueries(searchTerm);
                                const matchingQuery = queries.find(query => client.clientIpv4Address.toLowerCase().includes(query));
                                const queryColor = matchingQuery ? getColorForQuery(matchingQuery, queries) : null;
                                return (
                                <Col md={3} className="mb-3" key={`${client.clientId}-${client.networkName}`}>
                                    <Card>
                                        <Card.Header>
                                            <Row>
                                                <Col className="text-left">
                                                    <i className="bi bi bi-pc-display text-warning"></i>&nbsp;<span className="text-light">{client.clientDescription}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-left">
                                                    <span className="text-left" style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier", color: queryColor, fontSize: '0.85rem' }}>{client.clientIpv4Address}</span>
                                                </Col>
                                                <Col>
                                                        <span className="text-right" style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier", fontSize: '0.85rem' }}>{client.clientMac}</span>
                                                </Col>
                                            </Row>
                                            <Row style={{ fontSize: "0.85rem" }}>
                                                <Col xs={8} className="text-left">{client.networkName}</Col>
                                                <Col xs={4} className="text-right">VLAN: {client.vlan}&nbsp;&nbsp;<span className={getStatusClassName(client.status)}>
                                                    {getConnectionTypeIcon(client.lastConnectedNetworkConnectionType)}</span></Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <span className="d-none">IPv4: {client.clientIpv4Address}<br /></span>
                                                <span className="text-light">MFG:</span> <span style={{ color: queryColor }}>{client.manufacturer}</span><br /><br />
                                            <span className="text-light">First Seen:</span> {formatDateTime(client.firstSeen)}<br />
                                            <span className="text-light">Last Seen:</span> {formatDateTime(client.lastSeen)}<br /><br />
                                                <span className="text-light">Host:</span> {client.lastConnectedNetworkDeviceName} {client.switchport && ` @ Port: ${client.switchport}`}<br />
                                            <span className="text-light">SSID:</span> {client.ssid}<br />
                                            <span className="text-light">OS:</span> {client.os}
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row>
                                                <Col xs={8} className="text-left">
                                                        <span className="text-light" style={{ fontFamily: 'courier', }}>SND:</span>&nbsp;{convertKBtoGB(client.usageSentKBytes)} GB<br />
                                                        <span className="text-light" style={{ fontFamily: 'courier', }}>RCV:</span>&nbsp;{convertKBtoGB(client.usageRecvKBytes)} GB
                                                </Col>
                                                <Col xs={4} className="text-right text-light">
                                                    <span className="text-warning" title="Response time in Miliseconds">
                                                        <small><FetchMonitorResponseTime ipv4={client.clientIpv4Address} /></small>
                                                    </span><br />
                                                        <Button
                                                            size="sm"
                                                            variant="link"
                                                            className="text-right mx-0 px-0 my-0 py-0 text-info"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(`/nmap?ip=${client.clientIpv4Address}`, '_blank');
                                                            }}>
                                                            Port Scan
                                                        </Button></Col>
                                                    <Col xs={4} className="text-right d-none">Received:<br />{convertKBtoGB(client.usageRecvKBytes)} GB</Col>
                                            </Row>
                                            {/* Status: {client.status}<br />
                                            User: {client.user}<br />
                                            IPv6: {client.clientIpv6Address}<br />
                                            Connection Type: {client.lastConnectedNetworkConnectionType}<br />
                                            First Seen: {formatDateTime(client.firstSeen)}<br />
                                            Last Seen: {formatDateTime(client.lastSeen)}<br />
                                            SSID: {client.ssid}<br />
                                            OS: {client.os}
                                             */}
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                );
                            })}
                        </Row>
                    )}

                    <Row className="mt-0 pt-0">
                        <Col md={12} className="text-center">
                            <Button variant="link" onClick={() => handlePageChange(1)} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiArrowToLeft />
                            </Button>
                            <Button variant="link" onClick={() => handlePageChange(Math.max(1, currentPage - 1))} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiLeftArrow />
                            </Button>
                            {renderPageNumbers()}
                            <Button variant="link" onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiRightArrow />
                            </Button>
                            <Button variant="link" onClick={() => handlePageChange(totalPages)} style={{ minWidth: '40px', textAlign: 'center' }}>
                                <BiArrowToRight />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

const FetchMonitorResponseTime = ({ ipv4 }) => {
    const [responseTime, setResponseTime] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const db = firebase.database().ref('/kuma-devices/monitor_response_time');
            db.orderByChild('monitor_hostname').equalTo(ipv4).once('value', snapshot => {
                const data = snapshot.val();
                if (data) {
                    const firstKey = Object.keys(data)[0];
                    setResponseTime(data[firstKey].value);
                }
            });
        };
        fetchData();
    }, [ipv4]);

    return responseTime ? `@ ${responseTime}ms` : '';
};
