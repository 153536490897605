import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';

const mediaSources = {
    site3: 'https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1&loop=0',
    site4: 'https://3d43fc0b7346871fce63b1504b58dfd8.r2.cloudflarestorage.com/property-videos/Bioterra2024.mp4',
    biovista: 'https://lfrep.com/wp-content/uploads/2022/09/Biovista-aerial-exterior-2023-03-08-.webp',
    bioterra: 'https://bioterrasd.com/wp-content/uploads/2025/02/Bioterra-exterior-2024-11-20-7-1.jpg',
    bioterra2: 'https://player.vimeo.com/video/835625147?background=1&api=1&loop=0&title=0&byline=0&portrait=0&autoplay=1&muted=1',
    route66: 'https://videos.pexels.com/video-files/31220549/13335590_2560_1440_24fps.mp4',
    default: '/images/default.png',
};

const overlayLogos = {
    site1: 'https://example.com/logos/bioterra.png',
    site2: 'https://example.com/logos/vimeo.png',
    site3: 'https://example.com/logos/youtube.png',
    site4: 'https://example.com/logos/video.png',
    biovista: 'https://biovistasd.com/wp-content/uploads/2021/07/logo.svg',
    route66: 'https://biovistasd.com/wp-content/uploads/2021/07/logo.svg',
    bioterra: 'https://bioterrasd.com/wp-content/uploads/2023/05/Bioterra_Logo_Horizontal_RGB_For_Green_Background.png',
    bioterra2: 'https://bioterrasd.com/wp-content/uploads/2023/05/Bioterra_Logo_Horizontal_RGB_For_Green_Background.png',
    default: 'https://example.com/logos/default.png',
};

const defaultGifOverlay =
    'https://wipplecdn.b-cdn.net/wp-content/uploads/2021/02/d2dda3f0-750b-428e-a81d-6bd9a881f324-2-1.gif';

/**
 * Each recognized parameter has:
 * - key: the query string key
 * - label: user-friendly label
 * - noValue?: boolean (true if param doesn't require a value to be considered PASS)
 * - exampleValue: example for the table
 * - description: short explanation for the table
 * - usageExample: how the param might look in a URL
 */
const recognizedParams = [
    {
        key: 'title',
        label: 'Title',
        exampleValue: 'Hello%20World',
        description: 'Sets the big title text on the screen.',
        usageExample: '?title=Hello%20World',
    },
    {
        key: 'subtext',
        label: 'Subtext',
        exampleValue: 'Enjoy%20Your%20Day',
        description: 'Sets the smaller text under the title.',
        usageExample: '?subtext=Enjoy%20Your%20Day',
    },
    {
        key: 'site',
        label: 'Site',
        exampleValue: 'site3',
        description: 'Chooses a preset background (image or video).',
        usageExample: '?site=site3',
    },
    {
        key: 'customMedia',
        label: 'Custom Media',
        exampleValue: 'https://example.com/myvideo.mp4',
        description: 'Lets you pick your own background image or video.',
        usageExample: '?customMedia=https://example.com/myvideo.mp4',
    },
    {
        key: 'customLogo',
        label: 'Custom Logo',
        exampleValue: 'https://example.com/mylogo.png',
        description: 'Lets you pick a custom logo image.',
        usageExample: '?customLogo=https://example.com/mylogo.png',
    },
    {
        key: 'hideLogos',
        label: 'Hide Logos',
        noValue: true,
        exampleValue: '(no value needed)',
        description: 'Hides both logos on the screen.',
        usageExample: '?title=Hello&hideLogos',
    },
    {
        key: 'disableGif',
        label: 'Disable GIF',
        noValue: true,
        exampleValue: '(no value needed)',
        description: 'Stops the GIF overlay from showing at all.',
        usageExample: '?customMedia=https://example.com/bg.jpg&disableGif',
    },
    {
        key: 'customGif',
        label: 'Custom GIF',
        exampleValue: 'https://example.com/myCoolGif.gif',
        description: 'Uses a different GIF or image for the overlay.',
        usageExample: '?customGif=https://example.com/myCoolGif.gif',
    },
    {
        key: 'titleColor',
        label: 'Title Color',
        exampleValue: 'FF0000',
        description: 'Sets the title color by HEX (no # needed).',
        usageExample: '?title=Hello&titleColor=FF0000',
    },
    {
        key: 'subtextColor',
        label: 'Subtext Color',
        exampleValue: '00FF00',
        description: 'Sets the subtext color by HEX (no # needed).',
        usageExample: '?subtext=Green%20Text&subtextColor=00FF00',
    },
];

// Helper to detect if URL is a video
const isVideoUrl = (url) =>
    /\.(mp4|webm|ogg)$/i.test(url) || /youtube\.com|youtu\.be|vimeo\.com/.test(url);

const FullscreenDynamic = () => {
    const params = new URLSearchParams(window.location.search);

    // Determine status for each recognized param
    //   PASS if present and valid (or noValue param present)
    //   MISSING if not present, or empty value for those needing a value
    // We'll also track any unrecognized params in a separate array so we can list them with UNUSED.
    const recognizedWithStatus = [];
    const unrecognizedParams = [];

    recognizedParams.forEach((rp) => {
        if (params.has(rp.key)) {
            const value = params.get(rp.key);
            if (rp.noValue) {
                // e.g., hideLogos or disableGif
                recognizedWithStatus.push({ ...rp, status: 'PASS' });
            } else if (value) {
                recognizedWithStatus.push({ ...rp, status: 'PASS' });
            } else {
                recognizedWithStatus.push({ ...rp, status: 'MISSING' });
            }
        } else {
            recognizedWithStatus.push({ ...rp, status: 'MISSING' });
        }
    });

    // Identify unrecognized params
    for (const [key] of params.entries()) {
        const found = recognizedParams.some((rp) => rp.key === key);
        if (!found) {
            unrecognizedParams.push(key); // store the actual param name
        }
    }

    // If you want to color the first column based on PASS / MISSING / UNUSED
    const getBackgroundColor = (status) => {
        if (status === 'PASS') return 'limegreen';
        if (status === 'MISSING') return 'red';
        if (status === 'UNUSED') return 'grey';
        return 'white';
    };

    // Basic usage from the rest of the code
    const title = params.get('title') || 'Default Title';
    const subtext = params.get('subtext') || 'Default Subtext';
    const siteKey = params.get('site') || 'default';
    const hideLogos = params.has('hideLogos'); // no value needed
    const disableGif = params.has('disableGif'); // no value needed
    const customMedia = params.get('customMedia');
    const customLogo = params.get('customLogo');
    const customGif = params.get('customGif');
    const titleColorParam = params.get('titleColor');
    const titleColor = titleColorParam ? `#${titleColorParam}` : '#fff';
    const subtextColorParam = params.get('subtextColor');
    const subtextColor = subtextColorParam ? `#${subtextColorParam}` : '#fff';

    // Lightbox
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const handleOpenLightbox = () => {
        setLightboxOpen(true);
    };
    const handleCloseLightbox = () => {
        setLightboxOpen(false);
    };

    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') handleCloseLightbox();
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, []);

    // Media logic
    const [currentMedia, setCurrentMedia] = useState('media');
    const [localTime, setLocalTime] = useState('');
    const videoRef = useRef(null);
    const waitTime = 11000;

    const chosenMediaUrl = customMedia || mediaSources[siteKey] || mediaSources.default;
    const isVideo = isVideoUrl(chosenMediaUrl);
    const overlayLogoUrl = customLogo || overlayLogos[siteKey] || overlayLogos.default;
    const gifOverlayUrl = customGif || defaultGifOverlay;

    useEffect(() => {
        let isMounted = true;

        const cyclePlayback = () => {
            if (!isMounted) return;
            setCurrentMedia('media');
            let duration = waitTime;

            if (isVideo && videoRef.current && videoRef.current.duration) {
                duration = (videoRef.current.duration || 10) * 1000;
            }
            if (!disableGif) {
                setTimeout(() => {
                    if (!isMounted) return;
                    setCurrentMedia('gif');
                    setTimeout(() => {
                        if (!isMounted) return;
                        cyclePlayback();
                    }, waitTime);
                }, duration);
            }
        };

        cyclePlayback();
        return () => {
            isMounted = false;
        };
    }, [chosenMediaUrl, isVideo, disableGif]);

    // Update local time
    useEffect(() => {
        const updateTime = () => {
            setLocalTime(new Date().toLocaleString());
        };
        updateTime();
        const intervalId = setInterval(updateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Hide Bootstrap navbar, remove body margin/padding
    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) navbar.style.display = 'none';
        document.body.style.margin = '0';
        document.body.style.padding = '0';
        return () => {
            if (navbar) navbar.style.display = '';
            document.body.style.margin = '';
            document.body.style.padding = '';
        };
    }, []);

    return (
        <Container
            fluid
            style={{
                height: '100vh',
                position: 'relative',
                overflow: 'hidden',
                padding: 0,
                margin: 0,
                fontFamily: 'Parnaso Medium Condensed, sans-serif',
                fontWeight: 500,
                color: '#000',
            }}
        >
            {/* Background */}
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 0,
                }}
            >
                {currentMedia === 'gif' ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            background: `url(${gifOverlayUrl}) center center / contain no-repeat`,
                        }}
                    />
                ) : isVideo ? (
                    chosenMediaUrl.endsWith('.mp4') ? (
                        <video
                            ref={videoRef}
                            src={chosenMediaUrl}
                            autoPlay
                            muted
                            loop
                            playsInline
                            onLoadedMetadata={() => {
                                if (videoRef.current) videoRef.current.play();
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <iframe
                            ref={videoRef}
                            title="video"
                            src={chosenMediaUrl}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 0,
                            }}
                        />
                    )
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            background: `url(${chosenMediaUrl}) center center / cover no-repeat`,
                        }}
                    />
                )}
            </div>

            {/* Overlay content (title, subtext, logos) */}
            {currentMedia !== 'gif' && (
                <div
                    style={{
                        position: 'relative',
                        zIndex: 1,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            padding: '2rem',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {!hideLogos && (
                            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <img
                                    src="https://lfrep.com/wp-content/uploads/2022/10/logo.png"
                                    alt="Main Logo"
                                    style={{ maxWidth: '220px', filter: 'drop-shadow(0 0 10px #000)' }}
                                />
                                <img
                                    src={overlayLogoUrl}
                                    alt="Overlay Logo"
                                    style={{ maxWidth: '220px', filter: 'drop-shadow(0 0 10px #000)' }}
                                />
                            </div>
                        )}
                        <h1
                            style={{
                                color: titleColor,
                                textShadow: '0 0 6px #000',
                                fontFamily: 'Parnaso Medium Condensed, sans-serif',
                            }}
                        >
                            {title}
                        </h1>
                    </div>
                    <div style={{ padding: '2rem', textAlign: 'center' }}>
                        <h2
                            style={{
                                color: subtextColor,
                                textShadow: '0 0 6px #000',
                            }}
                        >
                            {subtext}
                        </h2>
                        <h3
                            style={{
                                color: '#ccc',
                                textShadow: '0 0 4px #000',
                                marginTop: '1rem',
                            }}
                        >
                            {localTime}
                        </h3>
                    </div>
                </div>
            )}

            {/* Bottom-right button */}
            <div
                onClick={handleOpenLightbox}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    zIndex: 9999,
                }}
            >
                <span style={{ color: '#000', fontSize: '20px', lineHeight: 1 }}>?</span>
            </div>

            {/* Lightbox with single table combining the param check + how-to guide */}
            {lightboxOpen && (
                <div
                    onClick={(e) => {
                        if (e.target.id === 'lightbox-background') {
                            handleCloseLightbox();
                        }
                    }}
                    id="lightbox-background"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            background: '#fff',
                            padding: '1rem',
                            borderRadius: '6px',
                            maxWidth: '90%',
                            minWidth: '70%',
                            maxHeight: '90%',
                            overflowY: 'auto',
                            position: 'relative',
                            fontFamily: 'Verdana, sans-serif',
                        }}
                    >
                        <div
                            onClick={handleCloseLightbox}
                            style={{
                                position: 'absolute',
                                top: '8px',
                                right: '12px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            X
                        </div>

                        <h5 style={{ marginBottom: '1rem' }}>How to Use These URL Queries</h5>
                        <p style={{ fontSize: '12px' }}>
                            This table shows each recognized URL parameter, whether it passed or is missing, and
                            what it does. To add more than one parameter, connect them with <strong>&</strong>.
                        </p>

                        <table
                            style={{
                                borderCollapse: 'collapse',
                                width: '100%',
                                marginTop: '1rem',
                                fontSize: '12px',
                            }}
                        >
                            <thead>
                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                    <th
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            textAlign: 'left',
                                            width: '10%',
                                        }}
                                    >
                                        Status
                                    </th>
                                    <th
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            textAlign: 'left',
                                            width: '10%',
                                        }}
                                    >
                                        Parameter
                                    </th>
                                    <th
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            textAlign: 'left',
                                            width: '20%',
                                        }}
                                    >
                                        Example Value
                                    </th>
                                    <th
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            textAlign: 'left',
                                            width: '30%',
                                        }}
                                    >
                                        What It Does
                                    </th>
                                    <th
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            textAlign: 'left',
                                            width: '30%',
                                        }}
                                    >
                                        Usage Example
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {recognizedWithStatus.map((p) => (
                                    <tr key={p.key}>
                                        {/* First column merges PASS / MISSING / UNUSED with background color */}
                                        <td
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                backgroundColor: getBackgroundColor(p.status),
                                                color: '#fff',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {p.status}
                                        </td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{p.label}</td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{p.exampleValue}</td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{p.description}</td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{p.usageExample}</td>
                                    </tr>
                                ))}

                                {/* Any unrecognized params get listed as separate rows (status=UNUSED) */}
                                {unrecognizedParams.map((up) => (
                                    <tr key={up}>
                                        <td
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                backgroundColor: getBackgroundColor('UNUSED'),
                                                color: '#fff',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            UNUSED
                                        </td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{up}</td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>N/A</td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                            This parameter is not recognized by the system.
                                        </td>
                                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>N/A</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <h5 style={{ marginTop: '1rem' }}>How to Combine Queries</h5>
                        <p style={{ fontSize: '12px' }}>
                            Start your link with a <code>?</code> before the first query. For each extra query, use{' '}
                            <code>&</code>.
                        </p>
                        <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
                            Example:
                            <br />
                            https://www.example.com/FullscreenDynamic
                            <strong><span className="text-warning">?</span><span className="text-success">title=Hello</span><span className="text-danger">&</span><span className="text-success">customMedia=https://example.com/video.mp4</span><span className="text-danger">&</span><span className="text-success">hideLogos</span></strong>
                        </p>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default FullscreenDynamic;
