import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { Row, Col, Container, Table, Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const firebaseConfig = {
    // Add your Firebase configuration here
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const getTimeDifference = (lastSeen) => {
    if (!lastSeen) return "N/A";

    const now = new Date();
    const lastSeenDate = new Date(lastSeen);
    const diffInSeconds = Math.floor((now - lastSeenDate) / 1000);

    const timeFormats = [
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
    ];

    for (const format of timeFormats) {
        const interval = Math.floor(diffInSeconds / format.seconds);
        if (interval > 0) {
            return `Last seen: ${interval} ${format.label}${interval !== 1 ? "s" : ""} ago`;
        }
    }

    return "Just now";
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const PublicHostnames = () => {
    const query = useQuery();
    const initialSearch = query.get("search") || "";
    const [tunnels, setTunnels] = useState([]);
    const [clients, setClients] = useState({});
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: "network", direction: "asc" });
    const [searchQuery, setSearchQuery] = useState(initialSearch);

    useEffect(() => {
        const db = firebase.database().ref("/clients");

        const handleClientSnapshot = (snapshot) => {
            const data = snapshot.val();
            let clientMap = {};

            if (data) {
                for (let clientId in data) {
                    for (let networkName in data[clientId]) {
                        const client = data[clientId][networkName];
                        clientMap[client.clientIpv4Address] = {
                            name: client.clientDescription || "Unknown",
                            mac: client.clientMac || "Unknown",
                            network: networkName || "Unknown",
                            lastSeen: client.lastSeen
                                ? new Date(client.lastSeen).toLocaleString(undefined, {
                                    timeZoneName: "short",
                                })
                                : "N/A",
                            lastSeenRelative: client.lastSeen ? getTimeDifference(client.lastSeen) : "N/A",
                        };
                    }
                }
            }
            setClients(clientMap);
        };

        db.on("value", handleClientSnapshot);
        return () => db.off("value", handleClientSnapshot);
    }, []);

    useEffect(() => {
        const tunnelsRef = firebase.database().ref("cloudflare_tunnels");

        const handleTunnelSnapshot = (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const formattedData = Object.values(data).flatMap((tunnel) =>
                    (Array.isArray(tunnel.public_hostnames) ? tunnel.public_hostnames : []).map((host) => {
                        const ipMatch = host.ip ? host.ip.match(/^(https?:\/\/)?([\d.]+)(:\d+)?$/) : null;
                        const ipOnly = ipMatch ? ipMatch[2] : "N/A"; // Extract only the IP, removing port
                        return {
                            tunnelName: tunnel.tunnel_name || "Unknown Tunnel",
                            url: host.url || "N/A",
                            ip: ipOnly,
                            client: clients[ipOnly] || null,
                        };
                    })
                );

                setTunnels(formattedData);
            } else {
                setTunnels([]);
            }
            setLoading(false);
        };

        tunnelsRef.on("value", handleTunnelSnapshot);
        return () => tunnelsRef.off("value", handleTunnelSnapshot);
    }, [clients]);

    const requestSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const filteredTunnels = tunnels.filter((tunnel) => {
        if (searchQuery.length < 2) return true; // Show all if search query is less than 2 characters

        const lowerQuery = searchQuery.toLowerCase();
        return (
            tunnel.client?.network?.toLowerCase().includes(lowerQuery) ||
            tunnel.client?.name?.toLowerCase().includes(lowerQuery) ||
            tunnel.url?.toLowerCase().includes(lowerQuery) ||
            tunnel.ip?.toLowerCase().includes(lowerQuery)
        );
    });

    const sortedTunnels = [...filteredTunnels].sort((a, b) => {
        const key = sortConfig.key;
        const valueA =
            key === "network"
                ? a.client?.network || ""
                : key === "lastSeen"
                    ? a.client?.lastSeen || ""
                    : a[key] || "";
        const valueB =
            key === "network"
                ? b.client?.network || ""
                : key === "lastSeen"
                    ? b.client?.lastSeen || ""
                    : b[key] || "";

        if (valueA < valueB) return sortConfig.direction === "asc" ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
    });

    return (
        <Container fluid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row className="mb-2">
                        <Col md="8">
                            <h4>Networked Systems with Remote Access - URL and IP</h4>
                            <p className="text-muted">
                                <strong>Note:</strong> Use the **URL** for remote access and the **IP** when on the local network.
                                Click any column header to sort ascending/descending as needed. All links open in a new tab/window.
                                All times are adjusted to your local timezone with automatic DST.
                            </p>
                        </Col>
                        <Col md="4">
                            {/* Search Input */}
                            <h4>Instant Search & Filter</h4>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Network Name, Client Name, URL, or Local IP"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <p className="text-muted">
                                    <strong>Note:</strong> Add{" "}
                                    <span className="">
                                        ?search=(Network, Client, URL, or IP) in URL for instant filter
                                    </span>
                                </p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ overflowX: "auto" }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    onClick={() => requestSort("network")}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Network Name{" "}
                                                    {sortConfig.key === "network"
                                                        ? sortConfig.direction === "asc"
                                                            ? <i className="bi bi-sort-alpha-up"></i>
                                                            : <i className="bi bi-sort-alpha-down-alt"></i>
                                                        : ""}
                                                </Button>
                                            </th>
                                            <th>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    onClick={() => requestSort("clientName")}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Client Name{" "}
                                                    {sortConfig.key === "clientName"
                                                        ? sortConfig.direction === "asc"
                                                            ? <i className="bi bi-sort-alpha-up"></i>
                                                            : <i className="bi bi-sort-alpha-down-alt"></i>
                                                        : ""}
                                                </Button>
                                            </th>
                                            <th>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    onClick={() => requestSort("url")}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    URL{" "}
                                                    {sortConfig.key === "url"
                                                        ? sortConfig.direction === "asc"
                                                            ? <i className="bi bi-sort-alpha-up"></i>
                                                            : <i className="bi bi-sort-alpha-down-alt"></i>
                                                        : ""}
                                                </Button>
                                            </th>
                                            <th>
                                                <Button variant="link" size="sm" style={{ textDecoration: "none" }}>
                                                    Access (Remote)
                                                </Button>
                                            </th>
                                            <th>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    onClick={() => requestSort("ip")}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Local IP{" "}
                                                    {sortConfig.key === "ip"
                                                        ? sortConfig.direction === "asc"
                                                            ? <i className="bi bi-sort-alpha-up"></i>
                                                            : <i className="bi bi-sort-alpha-down-alt"></i>
                                                        : ""}
                                                </Button>
                                            </th>
                                            <th>
                                                <Button variant="link" size="sm" style={{ textDecoration: "none" }}>
                                                    Access (Local)
                                                </Button>
                                            </th>
                                            <th>
                                                <Button variant="link" size="sm" style={{ textDecoration: "none" }}>
                                                    More Info
                                                </Button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedTunnels.length > 0 ? (
                                            sortedTunnels.map((tunnel, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {tunnel.client ? tunnel.client.network : "N/A"}
                                                        <br />
                                                        <Button
                                                            variant="info"
                                                            className="py-0 my-1 text-dark shadow-sm"
                                                            href={
                                                                tunnel.client
                                                                    ? `/devices?search=${tunnel.client.network}`
                                                                    : "#"
                                                            }
                                                            target="_blank"
                                                            disabled={!tunnel.client}
                                                        >
                                                            View Network Info
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {tunnel.client ? tunnel.client.name : "N/A"}
                                                        <br />
                                                        <span
                                                            className="text-warning"
                                                            style={{
                                                                fontWeight: "bold",
                                                                textTransform: "uppercase",
                                                                fontFamily: "courier",
                                                                fontSize: "1rem",
                                                            }}
                                                        >
                                                            {tunnel.client ? tunnel.client.lastSeenRelative : "N/A"}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {tunnel.url}
                                                        <br />
                                                        <span
                                                            className="text-secondary"
                                                            style={{
                                                                fontWeight: "bold",
                                                                textTransform: "uppercase",
                                                                fontFamily: "courier",
                                                                fontSize: "1rem",
                                                            }}
                                                        >
                                                            {" "}
                                                            Updated: {tunnel.client ? tunnel.client.lastSeen : "N/A"}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="success"
                                                            href={`https://${tunnel.url}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="my-0 py-1 text-dark"
                                                            disabled={tunnel.url === "N/A"}
                                                        >
                                                            Open URL
                                                        </Button>
                                                    </td>
                                                    <td style={{ fontFamily: "courier" }}>
                                                        {tunnel.ip}
                                                        <br />
                                                        <span
                                                            className="text-small text-danger"
                                                            style={{
                                                                fontWeight: "bold",
                                                                textTransform: "uppercase",
                                                                fontFamily: "courier",
                                                                fontSize: "0.85rem",
                                                            }}
                                                        >
                                                            {tunnel.client ? tunnel.client.mac : "N/A"}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="success"
                                                            href={`http://${tunnel.ip}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="my-0 py-1 text-dark"
                                                            disabled={tunnel.ip === "N/A"}
                                                        >
                                                            Open IP
                                                        </Button>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="warning"
                                                            href={`https://pbr.fonglellow.com/clients?search=${tunnel.ip}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="my-0 py-1 text-dark"
                                                            disabled={tunnel.ip === "N/A"}
                                                        >
                                                            Client Info
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No public hostnames found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default PublicHostnames;
