import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Table, Spinner, Form, Button } from 'react-bootstrap';

const firebaseConfig = {
    // Add your Firebase configuration here
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const SSIDComponent = () => {
    const [ssidData, setSsidData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [activeFilters, setActiveFilters] = useState({});

    useEffect(() => {
        const ssidRef = firebase.database().ref('ssids');
        ssidRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const formattedData = Object.entries(data).map(([networkName, details]) => ({
                    network: networkName,
                    ssids: details.ssids,
                }));
                setSsidData(formattedData);
                populateFilters(formattedData);
            } else {
                setSsidData([]);
            }
            setLoading(false);
        });

        return () => ssidRef.off();
    }, []);

    const populateFilters = (data) => {
        const newFilters = {};
        data.forEach(network => {
            (network.ssids || []).forEach(ssid => {
                Object.entries(getFilterableValues(ssid)).forEach(([key, value]) => {
                    if (!newFilters[key]) newFilters[key] = {};
                    newFilters[key][value] = (newFilters[key][value] || 0) + 1;
                });
            });
        });
        setFilters(newFilters);
        setActiveFilters(Object.keys(newFilters).reduce((acc, key) => ({ ...acc, [key]: new Set() }), {}));
    };

    const getFilterableValues = (ssid) => {
        const formatted = formatValue(ssid);
        return {
            splashPage: ssid.splashPage,
            authMode: formatted.authMode,
            ipAssignment: formatted.ipAssignment,
            vlanTagging: ssid.useVlanTagging ? 'Yes' : 'No',
            visible: ssid.visible ? 'Yes' : 'No',
        };
    };

    const handleFilterChange = (category, value) => {
        setActiveFilters(prev => {
            const newSet = new Set(prev[category]);
            newSet.has(value) ? newSet.delete(value) : newSet.add(value);
            return { ...prev, [category]: newSet };
        });
    };

    const applyFilters = (ssid) => {
        const values = getFilterableValues(ssid);
        return Object.entries(activeFilters).every(([key, filterSet]) =>
            filterSet.size === 0 || filterSet.has(values[key])
        );
    };

    const formatValue = (ssid) => ({
        authMode: ssid.authMode === 'psk' ? 'Password' : ssid.authMode,
        splashPage: {
            'Click-through splash page': 'Splash Page'
        },
        ipAssignment: {
            'NAT mode': 'Meraki DHCP',
            'Layer 3 Roaming': 'Local + Roaming',
            'Bridge mode': 'Local Network',
        }[ssid.ipAssignmentMode] || ssid.ipAssignmentMode,
    });

    const calculateDynamicCounts = (category, value) => {
        let count = 0;
        ssidData.forEach(network => {
            (network.ssids || []).forEach(ssid => {
                if (ssid.enabled && applyFilters(ssid)) {
                    const values = getFilterableValues(ssid);
                    if (values[category] === value) count++;
                }
            });
        });
        return count;
    };

    const resetFilters = () => window.location.reload();

    return (
        <Container fluid>
            {loading ? (
                <Row className="justify-content-center">
                    <Spinner animation="border" />
                </Row>
            ) : (
                <>
                    <Row className="my-3">
                        <Col>
                            <h3 className="text-warning text-center">Wireless Networks</h3>
                                <p className="text-white text-center">
                                    <Button variant="secondary my-0 py-1 text-center" onClick={resetFilters}>Reset All Filters</Button><br />
                                    Auto-Updates at Midnight PST
                                </p>
                            
                        </Col>
                        {Object.entries(filters).map(([category, values]) => (
                            <Col key={category} md={2}>
                                <h5 className="text-capitalize text-white">{category}</h5>
                                {Object.keys(values).map(value => {
                                    const dynamicCount = calculateDynamicCounts(category, value);
                                    return (
                                        <Form.Check
                                            key={value}
                                            type="checkbox"
                                            label={`${value} (${dynamicCount})`}
                                            checked={activeFilters[category]?.has(value)}
                                            onChange={() => handleFilterChange(category, value)}
                                        />
                                    );
                                })}
                            </Col>
                        ))}
                    </Row>

                    <Row>
                            <Col>
                                <span className="text-white">Filter Information:</span><br />
                                Additional context about what each value means
                            </Col>
                            <Col>
                                <span className="text-white">Splash Page:</span><br />
                                None: Easy Access<br />
                                Click Through: Gated
                            </Col>
                            <Col>
                                <span className="text-white">AuthMode:</span><br />
                                open: Easy Access<br />
                                Password: Gated
                            </Col>
                            <Col>
                                <span className="text-white">IpAssignment:</span><br />
                                Meraki DHCP: No Local<br />
                                Layer 3 Roaming: Local+<br />
                                Local Network: Local
                            </Col>
                            <Col>
                                <span className="text-white">VlanTagging:</span><br />
                                No: Easy Access<br />
                                Yes: Gated
                            </Col>
                            <Col>
                                <span className="text-white">Visible:</span><br />
                                Yes: WiFi is Public<br />
                                No: WiFi is Private
                            </Col>
                    </Row>

                    {ssidData.map((network, index) => {
                        const filteredSsids = (network.ssids || []).filter(ssid => ssid.enabled && applyFilters(ssid));
                        if (filteredSsids.length === 0) return null;

                        return (
                            <div key={index}>
                                <Row className="mb-2 mt-4">
                                    <Col>
                                        <h3>{network.network}</h3>
                                    </Col>
                                </Row>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>SSID Name</th>
                                            <th>Splash Page</th>
                                            <th>Auth Mode</th>
                                            <th>IP Assignment</th>
                                            <th>VLAN Tagging</th>
                                            <th>VLAN ID</th>
                                            <th>Visible</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredSsids.map((ssid, idx) => {
                                            const formatted = formatValue(ssid);
                                            return (
                                                <tr key={idx}>
                                                    <td>{ssid.name}</td>
                                                    <td>{ssid.splashPage}</td>
                                                    <td>{formatted.authMode}</td>
                                                    <td>{formatted.ipAssignment}</td>
                                                    <td>{ssid.useVlanTagging ? 'Yes' : 'No'}</td>
                                                    <td>{ssid.defaultVlanId || 'N/A'}</td>
                                                    <td>{ssid.visible ? 'Yes' : 'No'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        );
                    })}
                </>
            )}
        </Container>
    );
};

export default SSIDComponent;
