import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Table, Button, Form, Modal, ToggleButtonGroup, ToggleButton, Container, Row, Col, InputGroup, FormControl, Tab, Nav, Card, ListGroup } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import SiteContacts from './SiteContacts';
import SiteRooms from './SiteRooms';

function MonitorStatusEditor() {
    const [monitorData, setMonitorData] = useState([]);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState('');
    const [showPassword, setShowPassword] = useState({ primary: false, secondary: false });
    const [tabInput1, setTabInput1] = useState('');
    const [tabInput2, setTabInput2] = useState('');
    const ispPhoneNumbers = {
        'AT&T': '+18883336651',
        'Celito': '+19198521238',
        'Comcast': '+18009346489',
        'COX': '+18663993699',
        'Frontier': '+18559814544',
        'Level 3': '+18772538353',
        'Spectrum': '+18558609068',
        'Talk Talk': '+442074572819', // Note: TalkTalk number uses a UK country code.
        'Verizon': '+18008374966',
        'WiLine': '+18884945436',
        "Not-Landlord": ""
    };
    const itRegionalTechs = {
        'US - East Coast (Central)': 'Daniel Lux',
        'US - East Coast (North)': 'Daniel Lux',
        'US - West Coast (San Diego)': 'Steven Jackson',
        'US - West Coast (Bay Area)': 'Steven Jackson',
        'EU - United Kingdom': 'Daniel Lux'
    };
    const itPhoneNumbers = {
        'Daniel Lux': '+19196237161',
        'Steven Jackson': '+16199648457'
    };

    useEffect(() => {
        const statusRef = firebase.database().ref('/kuma/monitor_response_time');
        statusRef.on('value', snapshot => {
            const statusData = snapshot.val() || {};
            setMonitorData(Object.entries(statusData).map(([key, value]) => [key, value]));
        });

        const notesRef = firebase.database().ref('/kuma/monitor_notes');
        notesRef.on('value', snapshot => {
            const notesData = snapshot.val() || {};
            setMonitorData(prevData => prevData.map(([key, value]) => [key, { ...value, ...notesData[key] }]));
        });

        return () => {
            statusRef.off();
            notesRef.off();
        };
    }, []);

    const handleEdit = (index) => {
        setCurrentEdit({ index, ...monitorData[index][1] });
        setShowModal(true);
    };

    const handleModalChange = (field, value) => {
        if (field === 'primary_isp') {
            setCurrentEdit(prev => ({
                ...prev,
                [field]: value,
                primary_isp_support: ispPhoneNumbers[value] || prev.primary_isp_support
            }));
        } else if (field === 'site_region') {
            const siteTech = itRegionalTechs[value];
            setCurrentEdit(prev => ({
                ...prev,
                [field]: value,
                site_tech: siteTech,
                site_tech_phone: itPhoneNumbers[siteTech] || prev.site_tech_phone
            }));
        } else if (field === 'site_tech') {
            setCurrentEdit(prev => ({
                ...prev,
                [field]: value,
                site_tech_phone: itPhoneNumbers[value] || prev.site_tech_phone
            }));
        } else {
            setCurrentEdit(prev => ({ ...prev, [field]: value }));
        }
    };

    const handleSave = () => {
        const { monitor_name } = currentEdit;
        const updatedEdit = { ...currentEdit, tabInput1, tabInput2 };
        // firebase.database().ref(`/kuma/monitor_status/${monitor_name}`).update(currentEdit);
        firebase.database().ref(`/kuma/monitor_notes/${monitor_name}`).set(currentEdit);
        setShowModal(false);
        setCurrentEdit(null);
    };

    const handleCancel = () => {
        setShowModal(false);
        setCurrentEdit(null);
    };

    const handleDelete = (monitorName) => {
        if (window.confirm("Deleting a record only removes the contents until it's next sync. Remember to PAUSE the monitor in UpTime Kuma.")) {
            firebase.database().ref(`/kuma/monitor_response_time/${monitorName}`).remove();
            firebase.database().ref(`/kuma/monitor_notes/${monitorName}`).remove();
        }
    };

    const handleNavigateRecord = (direction) => {
        const newIndex = currentEdit.index + direction;
        if (newIndex >= 0 && newIndex < monitorData.length) {
            setCurrentEdit({ index: newIndex, ...monitorData[newIndex][1] });
        }
    };

    const handleCommentSubmit = (event) => {
        event.preventDefault();
        const timestamp = new Date().toISOString();
        firebase.database().ref(`/kuma/monitor_status/${currentEdit.monitor_name}/comments`).push({
            comment: comments,
            timestamp
        });
        setComments('');
    };

    const dialNumber = (number) => {
        window.open(`tel:${number}`);
    };

    const openWebsite = (url) => {
        window.open(`href:${url}`);
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const exportToCSV = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(monitorData.map(([name, data]) => ({
            monitor_name: name,
            ...data,
            primary_isp_account: data.primary_isp_account,
            primary_isp_pin: data.primary_isp_pin,
            primary_isp_support: data.primary_isp_support,
            // secondary_isp_account: data.secondary_isp_account,
            // secondary_isp_pin: data.secondary_isp_pin,
            // secondary_isp_support: data.secondary_isp_support,
            monitor_location: data.monitor_location,
            network_summary: data.network_summary
        })));
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'monitor_data' + fileExtension);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const updates = {};
            data.forEach((row, index) => {
                if (index > 0) { // skip header
                    const monitor_name = row[0];
                    updates[`/kuma/monitor_status/${monitor_name}`] = {
                        monitor_name,
                        value: row[1],
                        primary_isp: row[2],
                        secondary_isp: row[3],
                        dataprise_monitoring: row[4],
                        dataprise_priority: row[5],
                        site_status: row[6],
                        monitor_hostname: row[7],
                        primary_isp_account: row[8],
                        primary_isp_pin: row[9],
                        primary_isp_support: row[10],
                        // secondary_isp_account: row[11],
                        // secondary_isp_pin: row[12],
                        // secondary_isp_support: row[13],
                        monitor_location: row[11],
                        network_summary: row[12]
                    };
                }
            });
            firebase.database().ref().update(updates);
        };
        reader.readAsBinaryString(file);
    };

    const handleSubmit = () => {
        if (window.confirm("Are you sure you want to submit?")) {
            handleFileUpload()
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={12} lg={4} className="h4">
                    Uptime Monitors & Property Actionables
                </Col>

                <Col xs={12} md={12} lg={4} className="h6 text-center">
                    FUTURE: instant search here
                </Col>

                <Col xs={12} md={12} lg={4} className="h6 text-right">
                    FUTURE: other relevant actionable
                </Col>
                
                <Col xs={12} md={12} lg={1} className="d-none">
                    {/* <Form.File
                        id="custom-file"
                        label="Choose file"
                        data-browse="Browse"
                        custom
                        onChange={handleFileUpload}
                        className="mb-3"
                    />
                </Col>
                <Col xs={6} md={4} lg={2} className="text-right">
                    <Button variant="primary" onClick={handleSubmit}>Upload CSV</Button>
                </Col>
                <Col xs={6} md={4} lg={2} className="text-right">
                    <Button onClick={exportToCSV} className="mb-3">Export CSV</Button>*/}
                </Col>
            </Row>
            <Row className="d-none d-lg-block">
                <Table striped bordered hover>
                    <thead>
                        <tr className="text-center">
                            <th>Uptime Monitor Name</th>
                            <th>IP Address & Latency</th>
                            {/* <th>Heartbeat</th>
                            <th>Secondary ISP</th>
                            <th>Monitored</th>
                            <th>Priority</th> */}
                            <th>Site Status</th>
                            <th>ISP</th>
                            <th>ISP Support</th>
                            {/* <th>BMS/BAS</th>
                            <th>Access</th> */}
                            <th>Site Tech</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {monitorData.map(([monitorName, details], index) => (
                            <tr key={monitorName}>
                                <td>{monitorName}</td>
                                <td className="text-left" style={{ fontFamily: "courier" }}><b>{details.monitor_hostname}</b>&nbsp;<span style={{ color: getColor(details.value), float: "right" }}>{details.value}ms</span></td>
                                {/* <td className="text-center" style={{ color: getColor(details.value), textTransform: "uppercase" }}>&#11044;&nbsp;{details.value}</td>
                                <td>{details.secondary_isp}</td>
                                <td className="text-center">
                                    <Button size="sm" variant={getMonitoringColor(details.dataprise_monitoring)} style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier" }}>{details.dataprise_monitoring}</Button>
                                </td>
                                <td className="text-center" style={{ color: getIpTypeColor(details.dataprise_priority) }}>
                                    <Button size="sm" variant={getPriorityColor(details.dataprise_priority)} style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier" }}>{details.dataprise_priority}</Button>
                                </td> */}
                                <td className={getBuildingStatus(details.site_status)} style={{ textAlign: "center" }}>{details.site_status}</td>
                                <td className="text-center">{details.primary_isp}
                                    <Button variant="success" className="shadow-sm ml-2 d-none" size="sm" onClick={() => dialNumber(monitorName.primary_isp_support)} style={{ marginLeft: "5px", float: "right" }}><i className="bi bi-telephone-outbound-fill text-white"></i></Button>
                                </td>
                                <td className="text-center">
                                    <a
                                        href={`tel:${details.primary_isp_support}`}
                                        title={`Call ${details.primary_isp_support}`}
                                        aria-label={`Call ${details.primary_isp_support}`}
                                    >
                                        {details.primary_isp_support}
                                    </a>
                                </td>
                            
                                {/* <td className="text-center">
                                    {details.bms_signin_cloud && (
                                        <Button
                                            variant="success"
                                            className="shadow-sm ml-2"
                                            size="sm"
                                            onClick={() => window.open(details.bms_signin_cloud, '_blank')}
                                            style={{ marginLeft: "5px"}}
                                        >
                                            Web GUI
                                        </Button>
                                    )}
                                </td>
                                <td className="text-center">
                                    {details.access_system_cloud && (
                                        <Button
                                            variant="success"
                                            className="shadow-sm ml-2"
                                            size="sm"
                                            onClick={() => window.open(details.access_system_cloud, '_blank')}
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Web GUI
                                        </Button>
                                    )}
                                </td> */}
                                <td className="text-center">{details.site_tech}
                                    <Button variant="success" className="shadow-sm ml-2 d-none" size="sm" onClick={() => dialNumber(monitorName.site_tech_phone)} style={{ marginLeft: "5px", float: "right" }}><i className="bi bi-telephone-outbound-fill text-white"></i></Button>
                                </td>
                                <td className="text-center">
                                    <Button variant="info" className="shadow-sm" size="sm" onClick={() => handleEdit(index)}>Edit</Button>
                                    <Button variant="danger" className="shadow-sm d-none" size="sm" onClick={() => handleDelete(monitorName)} style={{ marginLeft: "5px" }}>Delete</Button>
                                    {/* <Button variant="primary" className="shadow-sm" size="sm" onClick={() => dialNumber(monitorName.primary_isp_support)} style={{ marginLeft: "5px" }}>Call ISP</Button>
                                    <Button variant="dark" className="shadow-sm" size="sm" onClick={() => dialNumber(monitorName.site_tech)} style={{ marginLeft: "5px" }}>Call IT</Button>*/}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>

            <Row className="d-lg-none d-xl-none">
                {monitorData.map(([monitorName, details], index) => (
                    <Col sm={6} md={4} lg={3} className="mx-0 px-1">
                        <Card key={monitorName} className="mb-3">
                            <Card.Header>
                                <span className="float-left">{monitorName}</span><br /><span style={{ fontSize: "0.85em" }} className={getBuildingStatus(details.site_status)}>{details.site_status}</span>
                                
                            </Card.Header>
                            <Card.Body>
                                <Card.Title style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier" }} >
                                    {details.monitor_hostname}
                                    
                                </Card.Title>
                                <Card.Text>
                                    <b>Notes:</b> {details.monitor_notes}
                                </Card.Text>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item className="px-2">
                                        <b>IT Tech:</b> {details.site_tech}
                                        <Button variant="success" className="shadow-sm ml-2" size="sm" onClick={() => dialNumber(monitorName.site_tech_phone)} style={{ marginLeft: "5px", float: "right" }}><i className="bi bi-telephone-outbound-fill text-white"></i></Button>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="px-2">
                                        <b>ISP:</b> {details.primary_isp}
                                        <Button variant="success" className="shadow-sm ml-2" size="sm" onClick={() => dialNumber(monitorName.primary_isp_support)} style={{ marginLeft: "5px", float: "right" }}><i className="bi bi-telephone-outbound-fill text-white"></i></Button>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="px-2">
                                        <Row>
                                            <Col className="text-left">
                                                <b>BMS</b>:
                                                {details.bms_signin_cloud && (
                                                    <Button
                                                        variant="success"
                                                        className="shadow-sm ml-2"
                                                        size="sm"
                                                        onClick={() => window.open(details.bms_signin_cloud, '_blank')}
                                                        style={{ marginLeft: "5px" }}
                                                    >
                                                        Web GUI
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col className="text-left">
                                                <b>Access:</b>
                                                {details.access_system_cloud && (
                                                    <Button
                                                        variant="success"
                                                        className="shadow-sm ml-2"
                                                        size="sm"
                                                        onClick={() => window.open(details.access_system_cloud, '_blank')}
                                                        style={{ marginLeft: "5px" }}
                                                    >
                                                        Web GUI
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <Row>
                                    <Col xs={7} className="text-left px-0 mx-0" style={{ color: getIpTypeColor(details.dataprise_priority) }}>
                                        <span><Button size="sm" variant={getMonitoringColor(details.dataprise_monitoring)} style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier" }}>{details.dataprise_monitoring}</Button></span>
                                        <Button className="shadow-sm px-1 mx-1" size="sm" variant={getPriorityColor(details.dataprise_priority)} style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "courier" }}>{details.dataprise_priority}</Button>
                                    </Col>
                                    <Col xs={5} className="text-right px-0 mx-0">
                                        <Button variant="outline-info" className="shadow-sm" size="sm" onClick={() => handleEdit(index)}>Edit</Button>
                                        <Button variant="outline-danger" className="shadow-sm" size="sm" onClick={() => handleDelete(monitorName)} style={{ marginLeft: "5px" }}>Delete</Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>

            {currentEdit && (
                <Modal show={showModal} onHide={handleCancel} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>UpTime Monitor | <span className="text-light">{currentEdit.monitor_name || ''}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container defaultActiveKey="details">
                            <Nav variant="tabs" className="mb-2">
                                <Nav.Item>
                                    <Nav.Link eventKey="details">Network / ISP</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab1">BMS / BAS</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab2">Access Controls</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab3">Site Contacts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab4">Elevate / AV</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab5">Secret Sauce</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="details">
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formMonitorName">
                                                    <Form.Label>Monitor Name</Form.Label>
                                                    <Form.Control type="text" disabled value={currentEdit.monitor_name || ''} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formSiteStatus">
                                                    <Form.Label>Site Status</Form.Label>
                                                    <Form.Control as="select" value={currentEdit.site_status || ''} onChange={(e) => handleModalChange('site_status', e.target.value)}>
                                                        <option>Select Site Status</option>
                                                        <option>Active - Empty</option>
                                                        <option>Active - Tenants</option>
                                                        <option>Offline - Construction</option>
                                                        <option>Offline - Other</option>                                            
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId="formPrimaryISP">
                                                            <Form.Label>Internet Service Provider</Form.Label>
                                                            <Form.Control as="select" value={currentEdit.primary_isp || ''} onChange={(e) => handleModalChange('primary_isp', e.target.value)}>
                                                                <option default>Select an ISP</option>
                                                                <option>AT&T</option><option>Celito</option><option>COX</option>
                                                                <option>Comcast</option><option>Frontier</option><option>Level 3</option>
                                                                <option>Not-Landlord</option><option>Spectrum</option>
                                                                <option>Talk Talk</option><option>Verizon</option><option>WiLine</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="formPrimaryISPAccount">
                                                            <Form.Label>ISP Account Number</Form.Label>
                                                            <Form.Control type="text" value={currentEdit.primary_isp_account || ''} onChange={(e) => handleModalChange('primary_isp_account', e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group controlId="formPrimaryISPPin">
                                                            <Form.Label>ISP Account Passcode</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control type={showPassword.primary ? 'text' : 'password'} value={currentEdit.primary_isp_pin || ''} onChange={(e) => handleModalChange('primary_isp_pin', e.target.value)} />
                                                                <InputGroup.Append>
                                                                    <Button variant="outline-secondary" onClick={() => togglePasswordVisibility('primary')}>
                                                                        {showPassword.primary ? <i className="bi bi-eye-slash "></i> : <i className="bi bi-eye "></i>}
                                                                    </Button>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12}>
                                                                <Form.Group controlId="formPrimaryISPAccountPhone">
                                                                <Form.Label>ISP Support Phone</Form.Label>
                                                                <Form.Control disabled type="text" pattern="\\+[1-9]{1}[0-9]{7,14}$" value={currentEdit.primary_isp_support || ''} onChange={(e) => handleModalChange('primary_isp_support', e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="formNetworkNotes">
                                                            <Form.Label>General Notes</Form.Label>
                                                            <Form.Control as="textarea" rows={5} value={currentEdit.network_notes || ''} onChange={(e) => handleModalChange('network_notes', e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group controlId="formNetworkSummary">
                                                            <Form.Label>Network Summary</Form.Label>
                                                            <Form.Control as="textarea" rows={5} value={currentEdit.network_summary || ''} onChange={(e) => handleModalChange('network_summary', e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4} lg={3}>
                                                <Form.Group controlId="formDatapriseMonitoring">
                                                    <Form.Label>Dataprise Monitoring?</Form.Label>
                                                    <ToggleButtonGroup type="radio" name="dataprise_monitoring" value={currentEdit.dataprise_monitoring} onChange={(val) => handleModalChange('dataprise_monitoring', val)}>
                                                        <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm px-2" value="Yes">Yes</ToggleButton>
                                                        <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm px-2" value="No">No</ToggleButton>
                                                        <ToggleButton id="radio-pending" variant="outline-secondary" className="shadow-sm px-2" value="Pending">Pending</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={5}>
                                                <Form.Group controlId="formDataprisePriority">
                                                    <Form.Label>Dataprise Response Priority</Form.Label>
                                                    <ToggleButtonGroup type="radio" name="dataprise_priority" value={currentEdit.dataprise_priority} onChange={(val) => handleModalChange('dataprise_priority', val)}>
                                                        <ToggleButton id="radio-tier1" variant="outline-danger" className="shadow-sm px-2" value="Tier 1">Tier 1</ToggleButton>
                                                        <ToggleButton id="radio-tier2" variant="outline-primary" className="shadow-sm px-2" value="Tier 2">Tier 2</ToggleButton>
                                                        <ToggleButton id="radio-tier3" variant="outline-success" className="shadow-sm px-2" value="Tier 3">Tier 3</ToggleButton>
                                                        <ToggleButton id="radio-non-critical" variant="outline-secondary" className="shadow-sm px-2" value="Tier 4">Tier 4</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                                <Form.Group controlId="formSiteUses">
                                                    <Form.Label>Primary Site Use</Form.Label>
                                                    <Form.Control as="select" value={currentEdit.site_uses || ''} onChange={(e) => handleModalChange('site_uses', e.target.value)} >
                                                        <option default>Select Primary Use</option>
                                                        <option>Office Space</option><option>Conference Room</option><option>Fitness Center</option>
                                                        <option>General Amenities</option><option>Guest WiFi</option><option>Construction Site</option>
                                                        <option>Restaurant / Diner</option><option>Outdoor Patio</option><option>Marketing Suite</option>
                                                        <option>Future Office Space</option><option>Empty Buildings</option><option>Other</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <Form.Group controlId="formSiteLocation">
                                                    <Form.Label>Location - Lat / Long</Form.Label>
                                                    <Form.Control type="text" value={currentEdit.monitor_location || ''} onChange={(e) => handleModalChange('monitor_location', e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <Form.Group controlId="formStreetAddress">
                                                    <Form.Label>Physical Street Address</Form.Label>
                                                    <Form.Control type="text" value={currentEdit.monitor_address || ''} onChange={(e) => handleModalChange('monitor_address', e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                                <Form.Group controlId="formSiteRegion">
                                                    <Form.Label>Region</Form.Label>
                                                    <Form.Control as="select" value={currentEdit.site_region || ''} onChange={(e) => handleModalChange('site_region', e.target.value)} >
                                                        <option default>Select a Region</option>
                                                        <option>US - East Coast (Central)</option>
                                                        <option>US - East Coast (North) </option>
                                                        <option>US - West Coast (San Diego)</option>
                                                        <option>US - West Coast (Bay Area)</option>
                                                        <option>EU - United Kingdom</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                                <Form.Group controlId="formSiteContact">
                                                    <Form.Label>Site IT Manager</Form.Label>
                                                    <Form.Control as="select" value={currentEdit.site_tech || ''} onChange={(e) => handleModalChange('site_tech', e.target.value)} >
                                                        <option default>Select a Tech</option>
                                                        <option>Daniel Lux</option>
                                                        <option>Steven Jackson</option>
                                                        <option>Other - None</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                                <Form.Group controlId="formSiteContactPhone">
                                                    <Form.Label>IT Manager Phone</Form.Label>
                                                    <Form.Control type="text" pattern="\\+[1-9]{1}[0-9]{7,14}$" value={currentEdit.site_tech_phone || ''} onChange={(e) => handleModalChange('site_tech_phone', e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="d-none">
                                            <Col>
                                                <Form.Group controlId="formNetworkSummary">
                                                    <Form.Label>Network Summary</Form.Label>
                                                    <Form.Control as="textarea" rows={3} value={currentEdit.network_summary || ''} onChange={(e) => handleModalChange('network_summary', e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="d-none">
                                            <Col>
                                                <Form.Group controlId="formComments">
                                                    <Form.Label>Comments</Form.Label>
                                                    <Form.Control as="textarea" rows={2} value={comments} onChange={(e) => setComments(e.target.value)} />
                                                    <Button onClick={handleCommentSubmit} style={{ marginTop: '10px' }}>Submit Comment</Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {/* <Form.Group controlId="formMonitorHostname">
                                            <Form.Label>Hostname</Form.Label>
                                            <Form.Control type="text" disabled value={currentEdit.monitor_hostname || ''} />
                                        </Form.Group> */}
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab1">
                                    {/* <Form.Group controlId="formTabInput1">
                                        <Form.Label>Tab 1 Input</Form.Label>
                                        <Form.Control type="text" value={tabInput1} onChange={(e) => setTabInput1(e.target.value)} />
                                    </Form.Group> */}
                                    <Row>
                                        <Col xs={4} md={2}>
                                            <Form.Group controlId="formHasBMS">
                                                <Form.Label>Site has BMS?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="has_bms" value={currentEdit.has_bms} onChange={(val) => handleModalChange('has_bms', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={5}>
                                            <Form.Group controlId="formBMSSystem">
                                                <Form.Label>BMS System - Vendor / Make</Form.Label>
                                                <Form.Control type="text" value={currentEdit.bms_system || ''} onChange={(e) => handleModalChange('bms_system', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={5}>
                                            <Form.Group controlId="formBMSVersion">
                                                <Form.Label>BMS Version - Model / Firmware</Form.Label>
                                                <Form.Control type="text" value={currentEdit.bms_version || ''} onChange={(e) => handleModalChange('bms_version', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} md={2}>
                                            <Form.Group controlId="formPCPresent">
                                                <Form.Label>BMS has PC?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_present" value={currentEdit.has_pc} onChange={(val) => handleModalChange('pc_present', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={5}>
                                            <Form.Group controlId="formPCHostname">
                                                <Form.Label>PC Hostname - Note in Meraki</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_hostname || ''} onChange={(e) => handleModalChange('pc_hostname', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={5}>
                                            <Form.Group controlId="formPCIPAddress">
                                                <Form.Label>PC IP Address - If on Local Network</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_ipaddress || ''} onChange={(e) => handleModalChange('pc_ipaddress', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} md={3}>
                                            <Form.Group controlId="formPCWebGUI">
                                                <Form.Label>Local/Web GUI Port</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_webgui || ''} onChange={(e) => handleModalChange('pc_webgui', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={2}>
                                            <Form.Group controlId="formPCApplication">
                                                <Form.Label>Has PC App?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_application" value={currentEdit.pc_application} onChange={(val) => handleModalChange('pc_application', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={7}>
                                            <Form.Group controlId="formPCAppLink">
                                                <Form.Label>Link to PC App for download</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_applink || ''} onChange={(e) => handleModalChange('pc_applink', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBMSNotes">
                                                <Form.Label>Notes / Comments</Form.Label>
                                                <Form.Control as="textarea" rows={5} value={currentEdit.bms_notes || ''} onChange={(e) => handleModalChange('bms_notes', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} md={3}>
                                            <Form.Group controlId="formPCMake">
                                                <Form.Label>BMS PC Make</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_make || ''} onChange={(e) => handleModalChange('pc_make', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={3}>
                                            <Form.Group controlId="formPCModel">
                                                <Form.Label>BMS PC Model</Form.Label>
                                                <Form.Control type="text" value={currentEdit.pc_model || ''} onChange={(e) => handleModalChange('pc_model', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formPCType">
                                                <Form.Label>BMS PC Type</Form.Label>
                                                <Form.Control as="select" value={currentEdit.pc_type || ''} onChange={(e) => handleModalChange('pc_type', e.target.value)}>
                                                    <option>Select Size</option>
                                                    <option>Small - Mini PC/SFF</option>
                                                    <option>Medium - Half Pint mATX</option>
                                                    <option>Standard - Full Size ATX</option>
                                                    <option>Large - Desktop ESXi Host</option>
                                                    <option>Server Sm - Rack-Mounted 1U</option>
                                                    <option>Server Lg - Rack-Mounted 2U+</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={3}>
                                            <Form.Group controlId="formPCEstDate">
                                                <Form.Label>BMS PC Est. Date</Form.Label>
                                                <Form.Control type="date" value={currentEdit.pc_estDate || ''} onChange={(e) => handleModalChange('pc_estDate', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCAdminCreds">
                                                <Form.Label>Have Admin?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_creds_admin" value={currentEdit.pc_creds_admin} onChange={(val) => handleModalChange('pc_creds_admin', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCCredsShared">
                                                <Form.Label>Creds Shared?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_creds_shared" value={currentEdit.pc_creds_shared} onChange={(val) => handleModalChange('pc_creds_shared', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCAdminShared">
                                                <Form.Label>Admin Share?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_admin_shared" value={currentEdit.pc_admin_shared} onChange={(val) => handleModalChange('pc_admin_shared', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCDatapriseKaseya">
                                                <Form.Label>DP Kaseya?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_dp_kaseya" value={currentEdit.pc_dp_kaseya} onChange={(val) => handleModalChange('pc_dp_kaseya', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCDatapriseBackups">
                                                <Form.Label>DP Backups?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_dp_backup" value={currentEdit.pc_dp_backup} onChange={(val) => handleModalChange('pc_dp_backup', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <Form.Group controlId="formPCDatapriseCrowdstrike">
                                                <Form.Label>Crowdstrike?</Form.Label>
                                                <ToggleButtonGroup type="radio" name="pc_dp_crowdstrike" value={currentEdit.pc_dp_crowdstrike} onChange={(val) => handleModalChange('pc_dp_crowdstrike', val)}>
                                                    <ToggleButton id="radio-yes" variant="outline-success" className="shadow-sm" value="Yes">Yes</ToggleButton>
                                                    <ToggleButton id="radio-no" variant="outline-danger" className="shadow-sm" value="No">No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId="formBMSLocalSignIN">
                                                <Form.Label>Link to Local Sign In - On-Site Use</Form.Label>
                                                <Form.Control type="text" value={currentEdit.bms_signin_local || ''} onChange={(e) => handleModalChange('bms_signin_local', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId="formBMSCloudSignIn">
                                                <Form.Label>Link to Cloud Sign In - Remote Use</Form.Label>
                                                <Form.Control type="text" value={currentEdit.bms_signin_cloud || ''} onChange={(e) => handleModalChange('bms_signin_cloud', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    {/* <Form.Group controlId="formTabInput2">
                                        <Form.Label>Tab 2 Input</Form.Label>
                                        <Form.Control type="text" value={tabInput2} onChange={(e) => setTabInput2(e.target.value)} />
                                    </Form.Group> */}
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId="formBMSName">
                                                <Form.Label>Card Access System Name</Form.Label>
                                                <Form.Control type="text" value={currentEdit.access_system_name || ''} onChange={(e) => handleModalChange('access_system_name', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId="formBMSCloudSignIn">
                                                <Form.Label>Link to Cloud Sign In - Remote Use</Form.Label>
                                                <Form.Control type="text" value={currentEdit.access_system_cloud || ''} onChange={(e) => handleModalChange('access_system_cloud', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab3">
                                    {/* <Form.Group controlId="formTabInput2">
                                        <Form.Label>Tab 2 Input</Form.Label>
                                        <Form.Control type="text" value={tabInput2} onChange={(e) => setTabInput2(e.target.value)} />
                                    </Form.Group> */}
                                    <SiteContacts monitor={currentEdit.monitor_name} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab4">
                                    {/* <Form.Group controlId="formTabInput2">
                                        <Form.Label>Tab 2 Input</Form.Label>
                                        <Form.Control type="text" value={tabInput2} onChange={(e) => setTabInput2(e.target.value)} />
                                    </Form.Group> */}
                                    <SiteRooms monitor={currentEdit.monitor_name} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab5">
                                    {/* <Form.Group controlId="formTabInput2">
                                        <Form.Label>Tab 2 Input</Form.Label>
                                        <Form.Control type="text" value={tabInput2} onChange={(e) => setTabInput2(e.target.value)} />
                                    </Form.Group> */}
                                    <Form.Group controlId="formSecretSauce">
                                        <Form.Label>Ingredients List</Form.Label>
                                        <Form.Control as="textarea" rows={27} value={currentEdit.secret_sauce || ''} onChange={(e) => handleModalChange('secret_sauce', e.target.value)} />
                                    </Form.Group>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer className="text-centers mr-auto ml-auto">
                        <Button variant="info" className="shadow-sm px-2" onClick={() => dialNumber(currentEdit.primary_isp_support)}>Call ISP</Button>
                        <Button variant="warning" className="shadow-sm px-2" onClick={() => dialNumber(currentEdit.site_tech_phone)}>Call LF IT</Button>
                        <Button variant="warning" className="shadow-sm px-2" onClick={() => dialNumber(currentEdit.site_tech_phone)}>Call LF PM</Button>
                        <Button variant="warning" className="shadow-sm px-2" onClick={() => dialNumber(currentEdit.site_tech_phone)}>Call LF Eng</Button>
                        {/* <Button variant="info" className="shadow-sm" onClick={() => dialNumber(currentEdit.secondary_isp_support)}>Call Secondary ISP</Button> */}
                        <Button variant="outline-secondary" className="shadow-sm px-2" onClick={() => handleNavigateRecord(-1)}>Previous</Button>
                        <Button variant="outline-secondary" className="shadow-sm px-2" onClick={() => handleNavigateRecord(1)}>Next</Button>
                        <Button variant="success" className="shadow-sm px-2" onClick={handleSave}>Save Changes</Button>
                        <Button variant="secondary" className="shadow-sm px-2" onClick={handleCancel}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Container>
    );
}

export default MonitorStatusEditor;

function getColor(value) {
    return value === '1' ? 'chartreuse' : value === '0' ? 'red' : 'goldenrod';
}

function getIpTypeColor(status) {
    return status === 'Tier 1' ? 'red' : status === 'Tier 2' ? 'blue' : status === 'Tier 3' ? 'green' : 'grey';
}

function getPriorityColor(status) {
    return status === 'Tier 1' ? 'danger' : status === 'Tier 2' ? 'warning' : status === 'Tier 3' ? 'success' : status === 'Tier 4' ? 'info' : '';
}

function getMonitoringColor(status) {
    return status === 'Yes' ? 'success' : status === 'No' ? 'danger' : '';
}

function getBuildingStatus(status) {
    return status === 'Active - Tenants' ? 'text-success' : status === 'Active - Empty' ? 'text-warning' : status === 'Offline - Construction' ? 'text-danger' : status === 'Offline - Other' ? 'text-secondary' : '';
}