
import React from 'react'
import { Navbar, Nav, Image } from 'react-bootstrap'

import { useAuth } from "../contexts/FirebaseAuth"

import "./TopNav.css"

export default function TopNav() {
    const { currentUser } = useAuth()
    //const [expanded, setExpanded] = useState(false)

    return (
        <Navbar bg="dark" // controls nav background color
            fixed="top" // keeps nav at top (not necessary when sticky top is set)
            sticky="top" // pushes page below, prevents overlap when expanded
            expand="sm" // collapse when less wide than this breakpoint
            collapseOnSelect // collapse after a nav link has been clicked
            style={{ marginBottom: "15px"}}

            // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
            //expanded={expanded}
            //onToggle={next => setExpanded(next)}

        >
            <Navbar.Brand href="/" className="text-warning" style={{ fontSize: 22 }}><i class="bi bi-award-fill text-light"></i>PBR  | Longfellow REP</Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {/* <Nav.Link href="/networks" className="text-light">Netorks</Nav.Link> */}
                    <Nav.Link href="/devices" className="text-light px-3">Networks</Nav.Link>
                    <Nav.Link href="/clients" className="text-light px-3">Clients</Nav.Link>
                    <Nav.Link href="/remote" className="text-light px-3">Remote</Nav.Link>
                    <Nav.Link href="/printers" className="text-light px-3">Printers</Nav.Link>
                    <Nav.Link href="/ups" className="text-light px-3">UPS</Nav.Link>
                    <Nav.Link href="/monitors" className="text-light px-3">Uptime</Nav.Link>
                    {/* <Nav.Link href="/av-rooms" className="text-light px-3">AV Rooms</Nav.Link>
                    <Nav.Link href="/contacts" className="text-light px-3">Contacts</Nav.Link>
                    <Nav.Link href="/properties" className="text-light">Properties</Nav.Link> */}
                    {!currentUser &&
                        <Nav.Link href="/login" className="text-light px-3">Login</Nav.Link>
                    }
                </Nav>

               { currentUser &&
                    <Nav.Link href="/profile" className="profile-link">
                        <Image roundedCircle src={currentUser.photoURL} alt="user profile" height="35px"/>
                    </Nav.Link>
                }

            </Navbar.Collapse>
        </Navbar>
    )
}
